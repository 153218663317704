import React from 'react'
import { BatchUpload } from "components/home/batchupload";

const batchrequest = () => {
    return (
        <div>
            Batch Requests....
            <BatchUpload/>
        </div>
    )
}

export default batchrequest
